import React, { useState } from "react";
import { render } from "react-dom";
import ContactForm from "../contact-form/contact-form";

import LogoSmall from "../../imgs/logoBig.png";

import "./styles.scss";

const Navbar = () => {
  const [form, setForm] = useState(false);

  React.useEffect(() => {
    document.getElementById("dd-cont").style.width =
      document.getElementById("dd").offsetWidth + "px";
  }, []);

  render(
    <ContactForm open={form} setOpen={setForm} />,
    document.getElementById("formModal")
  );

  const locations = [
    {
      href: "/sycamore",
      desc: "Sycamore Tree by Dwellingo, HSR Layout, Sector 1",
    },
    {
      href: "/derive",
      desc: "Dérive by Dwellingo, Indiranagar 100 ft road",
    },
    {
      href: "/ombre",
      desc: "Ombré by Dwellingo, HSR Layout, Sector ",
    },
    {
      href: "/lush",
      desc: "Lush by Dwellingo, Whitefield, Brigade Tech Park",
    },
  ];

  return (
    <div className="navbar-container">
      <div className="nav-basket">
        <a href="/">
          <img src={LogoSmall} alt="logo" className="nav-logo" />
        </a>
        {/* <div className="navBtn" onClick={() => setForm(true)}>
          Hit Us Up
  </div> */}
        <div className="dd-cont-main">
          <div className="carousel-dd nav-btn" id="dd">
            Find Your Home!
          </div>
          <div className="dropdown-cont" id="dd-cont">
            {locations.map(({ href, desc }, index, arr) =>
              index === arr.length - 1 ? (
                <>
                  <hr />
                  <a style={{ textDecoration: "none" }} href={href}>
                    <div className="dropdown-elem dropdown-elem-last">
                      {desc}
                    </div>
                  </a>
                </>
              ) : (
                <>
                  <hr />
                  <a style={{ textDecoration: "none" }} href={href}>
                    <div className="dropdown-elem">{desc}</div>
                  </a>
                  <hr />
                </>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
