import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { render } from "react-dom";

import ContactForm from "../../components/contact-form/contact-form";
import Chevron from "../../imgs/ci_chevron-big-right.svg";

import { ReactComponent as SycamoreIcon01 } from "../../imgs/Sycamore Icons-01.svg";
import { ReactComponent as SycamoreIcon02 } from "../../imgs/Sycamore Icons-02.svg";
import { ReactComponent as SycamoreIcon03 } from "../../imgs/Sycamore Icons-03.svg";
import { ReactComponent as SycamoreIcon04 } from "../../imgs/Sycamore Icons-04.svg";
import { ReactComponent as SycamoreIcon06 } from "../../imgs/Sycamore Icons-06.svg";
import { ReactComponent as SycamoreIcon07 } from "../../imgs/Sycamore Icons-07.svg";
import { ReactComponent as SycamoreIcon09 } from "../../imgs/Sycamore Icons-09.svg";
import { ReactComponent as SycamoreIcon10 } from "../../imgs/Sycamore Icons-10.svg";
import SycamoreNear1 from "../../imgs/sycamore1.jfif";
import SycamoreNear2 from "../../imgs/sycamore2.jfif";
import SycamoreNear3 from "../../imgs/sycamore3.jfif";
import SycamoreNear4 from "../../imgs/sycamore4.jfif";
import SycamoreDPMain from "../../imgs/prpoImgs/1.png";
import SycamoreDPTall1 from "../../imgs/prpoImgs/8.png";
import SycamoreDPTall2 from "../../imgs/prpoImgs/14.png";
import SycamoreDPTall3 from "../../imgs/prpoImgs/5.png";
import SycamoreDPBlobMain from "../../imgs/prpoImgs/10.png";
import SycamoreDPBlobSec from "../../imgs/prpoImgs/11.png";
import DerNear from "../../imgs/Frame_1-removebg-preview.png";
import Call from "../../components/call/Call";

import "./derive.css";

const SycamoreNearData = [
  {
    img: SycamoreNear2,
    title: "Watering holes:",
    data: [
      {
        location: "Three Dots and a Dash:",
        distance: "5 min",
      },
      {
        location: "Indiranagar Social:",
        distance: "4 min",
      },
      {
        location: "Balcony Bar:",
        distance: "5 min",
      },
      {
        location: "Toit:",
        distance: "4 min",
      },
      {
        location: "Loft 38:",
        distance: "2 min",
      },
      {
        location: "Daddy's:",
        distance: "5 min",
      },
      {
        location: "Bob's Bar:",
        distance: "1 min",
      },
    ],
  },
  {
    img: SycamoreNear1,
    title: "Corporate Headquarters",
    data: [
      {
        location: "Dunzo:",
        distance: "5 min",
      },
      {
        location: "Cred:",
        distance: "3 min",
      },
      {
        location: "Snapdeal:",
        distance: "7 min",
      },
      {
        location: "Curefit:",
        distance: "8 min",
      },
      {
        location: "Ather:",
        distance: "5 min",
      },
    ],
  },
  {
    img: SycamoreNear3,
    title: "Hospitals:",
    data: [
      {
        location: "Manipal Hospital:",
        distance: "5 min",
      },
      {
        location: "Columbia Asia:",
        distance: "10 min",
      },
      {
        location: "Vikram hospital:",
        distance: "15 min",
      },
    ],
  },
  {
    img: SycamoreNear4,
    title: "Parks & Rec:",
    data: [
      {
        location: "Indiranagar Park:",
        distance: "5 min",
      },
      {
        location: "Defence Colony Park:",
        distance: "10 min",
      },
    ],
  },
];

const iconsArray = [
  {
    img: SycamoreIcon01,
    alt: "Maintenance Experts",
  },
  {
    img: SycamoreIcon02,
    alt: "Daily deep-cleaning & Sanitization",
  },
  {
    img: SycamoreIcon03,
    alt: "High-Speed WiFi",
  },
  {
    img: SycamoreIcon04,
    alt: "On-Site Laundry",
  },
  {
    img: SycamoreIcon06,
    alt: "Fully Furnished",
  },
  {
    img: SycamoreIcon07,
    alt: "Paperless Agreements",
  },
  {
    img: SycamoreIcon09,
    alt: "RFID Access & Security",
  },
  {
    img: SycamoreIcon10,
    alt: "Resident-only App",
  },
];

const Derive = () => {
  const [form, setForm] = useState(false);

  render(
    <ContactForm open={form} setOpen={setForm} />,
    document.getElementById("formModal")
  );
  var formOpened = false;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
    document.getElementById("loadingAnimation").style.display = "flex";
    setTimeout(() => {
      if (document.getElementById("loadingAnimation")) {
        document.getElementById("loadingAnimation").style.display = "none";
      }
    }, 1000);
    document.getElementById("nav").style.display = "none";
    var imgSec1 = document.getElementById("propImgsSec1");
    var imgSec2 = document.getElementById("propImgsSec2");
    var imgSec3 = document.getElementById("propImgsSec3");
    // var imgSecLand = document.getElementById('last2Img2')
    document.addEventListener("scroll", function () {
      var value = window.scrollY;
      document.querySelector("body").style.height = "fit-content";

      var height = document.body.offsetHeight;

      if (value >= height / 2 && !formOpened) {
        formOpened = true;
        setForm(true);
      }

      if (imgSec1 && imgSec2 && imgSec3) {
        imgSec1.style.marginTop = "calc(-" + value / 3 + "px)";
        imgSec2.style.marginTop = "calc(-" + value / 10 + "px)";
        imgSec3.style.marginTop = "calc(-" + value / 50 + "px)";
        // imgSecLand.style.marginTop = 'calc(-' + value/20 + 'px)'
        // document.getElementById('finalImgCont').style.marginBottom = 'calc(' + imgSecLand.offsetHeight + 'px - 20vw - 150px)'
      }
    });
  }, []);

  return (
    <div className="propertyPageMainCont">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Fully furnished, bespoke 1BHK homes in the heart of Indiranagar 100
          feet road. | Dérive
        </title>
        <meta
          name="title"
          content="Fully furnished, bespoke 1BHK homes in the heart of Indiranagar 100 feet road. | Dérive"
        />
        <meta
          name="description"
          content="World class co-living, co-working studio spaces right in the heart of Indiranagar 100 feet road, Bangalore. A home for creatives, nomads, rebels to all come together under one roof. Work
Live & Love and get lost at Dérive by Dwellingo."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.dwellingo.in/derive" />
        <meta
          property="og:title"
          content="Fully furnished, bespoke 1BHK homes in the heart of Indiranagar 100 feet road. | Dérive"
        />
        <meta
          property="og:description"
          content="World class co-living, co-working studio spaces right in the heart of Indiranagar 100 feet road, Bangalore. A home for creatives, nomads, rebels to all come together under one roof. Work
Live & Love and get lost at Dérive by Dwellingo."
        />
        <meta
          property="og:image"
          content="https://www.dwellingo.in/logo512.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.dwellingo.in/derive"
        />
        <meta
          property="twitter:title"
          content="Fully furnished, bespoke 1BHK homes in the heart of Indiranagar 100 feet road. | Dérive"
        />
        <meta
          property="twitter:description"
          content="World class co-living, co-working studio spaces right in the heart of Indiranagar 100 feet road, Bangalore. A home for creatives, nomads, rebels to all come together under one roof. Work
Live & Love and get lost at Dérive by Dwellingo."
        />
        <meta
          property="twitter:image"
          content="https://www.dwellingo.in/logo512.png"
        />
      </Helmet>
      <Call bgc="#557054" />
      <div className="topC2aDer">
        Starting from Rs. 19,999 onwards.
        <span className="topc2a" onClick={() => setForm(true)}>
          Book Now
        </span>
      </div>
      <div className="propertyPageTopHead">
        World class co-living, co-working space right in the heart of Bangalore
      </div>
      <div className="propertyPageTopSubHead">Powered by Dwellingo</div>
      <div className="DerTitle">Dérive</div>
      <div className="DerSubTitle">
        A Dérive is an unplanned journey through a landscape on which the
        surrounding architecture and geography subconsciously direct travellers
        with the ultimate goal of encountering an antirely new and authentic
        experience. Come experience Dérive by Dwellingo at Indiranagar 100 feet
        road, Bangalore.
      </div>
      <img
        src={SycamoreDPMain}
        loading="lazy"
        alt="Derive Images"
        className="propImgsMain"
      />
      <div className="secImgsCont">
        <img
          src={SycamoreDPTall1}
          loading="lazy"
          alt="Derive Images"
          className="propImgsSec"
          id="propImgsSec1"
        />
        <img
          src={SycamoreDPTall2}
          loading="lazy"
          alt="Derive Images"
          className="propImgsSec"
          id="propImgsSec2"
        />
        <img
          src={SycamoreDPTall3}
          loading="lazy"
          alt="Derive Images"
          className="propImgsSec"
          id="propImgsSec3"
        />
      </div>
      <div className="propertyInfoPara1Der">Fully furnished bespoke homes</div>
      <div className="propertyInfoDesc1Der">
        “In a world where more walls have been built than at any period in
        history, by an establishment that thrusts you into boxes of control; One
        that frowns on your decision to not-conform or to exercise your freedom
        to say your piece unscathed, Dérive is a way of life”
      </div>
      <div className="finalImgCont" id="finalImgCont">
        <img
          src={SycamoreDPBlobMain}
          loading="lazy"
          alt="Derive Images"
          className="last2Img1"
        />
        <img
          src={SycamoreDPBlobSec}
          loading="lazy"
          alt="Derive Images"
          className="last2Img2"
          id="last2Img2"
        />
      </div>
      <div className="propMainTitleSizeDer work">Work</div>
      <div className="propMainTitleSizeDer liveAndLove">Live & Love</div>
      <div className="propMainTitleSizeDer underThe">while losing</div>
      <div className="propMainTitleSizeDer yourselfAt">yourself at</div>
      <div className="DerTitleSmall">Dérive</div>
      <div className="getInTouchCont" onClick={() => setForm(true)}>
        GET IN TOUCH
        <img src={Chevron} alt="chevron" style={{ marginTop: "3px" }} />
      </div>
      <div style={{ width: "fit-content", margin: "auto" }}>
        <div className="iconsCont">
          {iconsArray.map((e, index) => {
            return (
              <div key={index} className="iconBoxDer">
                <e.img className="iconsSVGDer" />
                {e.alt}
              </div>
            );
          })}
        </div>
      </div>
      <div className="POIDer">Places of interest around you</div>
      <img src={DerNear} loading="lazy" alt="" className="POInearImg" />
      <div className="getInTouchCont" onClick={() => setForm(true)}>
        GET IN TOUCH
        <img src={Chevron} alt="chevron" style={{ marginTop: "3px" }} />
      </div>
      <div className="main-carousel-cont propCarousel">
        <div className="mainCarousel propMainCarousel">
          {SycamoreNearData.map((e, index) => {
            return (
              <div key={index}>
                <img
                  src={e.img}
                  alt="img"
                  className="nearPropImgs"
                  loading="lazy"
                />
                <div className="propCarouselTitle">{e.title}</div>
                <ul className="propCarouUL">
                  {e.data.map((e, index) => (
                    <li key={index}>
                      <b>• {e.location}</b> {e.distance}
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
      <Helmet
        script={[
          {
            type: "text/javascript",
            innerHTML: `
             var _ua = ( function ( u ) {
      return {
        Tablet: ( u.indexOf( "windows" ) != -1 && u.indexOf( "touch" ) != -1 && u.indexOf( "tablet pc" ) == -1 )
          || u.indexOf( "ipad" ) != -1
          || ( u.indexOf( "android" ) != -1 && u.indexOf( "mobile" ) == -1 )
          || ( u.indexOf( "firefox" ) != -1 && u.indexOf( "tablet" ) != -1 )
          || u.indexOf( "kindle" ) != -1
          || u.indexOf( "silk" ) != -1
          || u.indexOf( "playbook" ) != -1,
        Mobile: ( u.indexOf( "windows" ) != -1 && u.indexOf( "phone" ) != -1 )
          || u.indexOf( "iphone" ) != -1
          || u.indexOf( "ipod" ) != -1
          || ( u.indexOf( "android" ) != -1 && u.indexOf( "mobile" ) != -1 )
          || ( u.indexOf( "firefox" ) != -1 && u.indexOf( "mobile" ) != -1 )
          || u.indexOf( "blackberry" ) != -1
      }
    } )( window.navigator.userAgent.toLowerCase() );

    var luxyEl = document.getElementById( 'root' );
    if ( luxyEl ) {
      if ( !_ua.Mobile && !_ua.Tablet ) {
        luxy.init( {
          wrapper: '#root',
          wrapperSpeed: 0.065,

        } );
        luxy.cancel()
      } else {
        luxyEl.style.position = 'unset'
      }
    }
          `,
          },
        ]}
      />
    </div>
  );
};

export default Derive;
