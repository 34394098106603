import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { render } from "react-dom";

import ContactForm from "../../components/contact-form/contact-form";
import Chevron from "../../imgs/ci_chevron-big-right.svg";

import { ReactComponent as MaintenanceIcon } from "../../imgs/Sycamore Icons-01.svg";
import { ReactComponent as CleaningIcon } from "../../imgs/Sycamore Icons-02.svg";
import { ReactComponent as WifiIcon } from "../../imgs/Sycamore Icons-03.svg";
import { ReactComponent as LaundryIcon } from "../../imgs/Sycamore Icons-04.svg";
import { ReactComponent as FurnitureIcon } from "../../imgs/Sycamore Icons-06.svg";
import { ReactComponent as PaperIcon } from "../../imgs/Sycamore Icons-07.svg";
import { ReactComponent as BadgeIcon } from "../../imgs/Sycamore Icons-09.svg";
import { ReactComponent as PhoneIcon } from "../../imgs/Sycamore Icons-10.svg";
import { ReactComponent as ChevronHead } from "../../imgs/ci_chevron-big-right.svg";

import CorporateHeadquarters from "../../imgs/sycamore1.jfif";
import WateringHoles from "../../imgs/sycamore2.jfif";
import Hospitals from "../../imgs/sycamore3.jfif";
import ParksAndRec from "../../imgs/sycamore4.jfif";
import Call from "../../components/call/Call";

import "./aecs.scss";

const meta = {
  title:
    "Fully furnished, bespoke studio rooms in the heart of Whitefield, Brigade Tech Park, Bangalore. | Lush",
  desc: "World class co-living, co-working studio spaces right in the heart of Whitefield, Brigade Tech Park, Bangalore. A home for creatives, nomads, rebels to all come together under one roof. Work, Live & Love and get lost at Lush by Dwellingo.",
  url: "Lush",
  property: "Lush",
  primaryColor: "#9dad82",
};

const NearData = [
  {
    img: WateringHoles,
    title: "Watering holes:",
    data: [
      {
        location: "Gilly's Restobar",
        distance: "14 min",
      },
      {
        location: "Gypsy Turtle",
        distance: "10 min",
      },
      {
        location: "Koshy's Pub",
        distance: "14 min",
      },
      {
        location: "FoxTrot",
        distance: "13 minutes",
      },
    ],
  },
  {
    img: CorporateHeadquarters,
    title: "Corporate Headquarters",
    data: [
      {
        location: "Bagmane Tech Park",
        distance: "21 min",
      },
      {
        location: "Colive Corporate Offices",
        distance: "20 min",
      },
      {
        location: "Cisco Campus ETV",
        distance: "22 min",
      },
    ],
  },
  {
    img: Hospitals,
    title: "Hospitals:",
    data: [
      {
        location: "Aayug Multi Speciality Hospital",
        distance: "6 min",
      },
      {
        location: "Hope Hospital",
        distance: "2 min",
      },
      {
        location: "Brookefield Hospital",
        distance: "3 min",
      },
      {
        location: "Lions Airport City Hospital",
        distance: "10 min",
      },
    ],
  },
  {
    img: ParksAndRec,
    title: "Parks & Rec:",
    data: [
      {
        location: "Brookefield Mall",
        distance: "3 min",
      },
      {
        location: "Chinappanahalli Lake",
        distance: "10 min",
      },
      {
        location: "Munnekolala Lake",
        distance: "7 min",
      },
      {
        location: "Jogging Park",
        distance: "5 min",
      },
    ],
  },
];

const iconsArray = [
  {
    img: MaintenanceIcon,
    alt: "Maintenance Experts",
  },
  {
    img: CleaningIcon,
    alt: "Daily deep-cleaning & Sanitization",
  },
  {
    img: WifiIcon,
    alt: "High-Speed WiFi",
  },
  {
    img: LaundryIcon,
    alt: "On-Site Laundry",
  },
  {
    img: FurnitureIcon,
    alt: "Fully Furnished",
  },
  {
    img: PaperIcon,
    alt: "Paperless Agreements",
  },
  {
    img: BadgeIcon,
    alt: "RFID Access & Security",
  },
  {
    img: PhoneIcon,
    alt: "Resident-only App",
  },
];

const Lush = () => {
  const [form, setForm] = useState(false);
  const formOpened = useRef(false);

  render(
    <ContactForm open={form} setOpen={setForm} />,
    document.getElementById("formModal")
  );
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
    document.getElementById("loadingAnimation").style.display = "none";
    document.getElementById("nav").style.display = "none";

    var imgSec1 = document.getElementById("propImgsSec1");
    var imgSec2 = document.getElementById("propImgsSec2");
    var imgSec3 = document.getElementById("propImgsSec3");
    // var imgSecLand = document.getElementById('last2Img2')
    document.addEventListener("scroll", function () {
      var value = window.scrollY;
      document.querySelector("body").style.height = "fit-content";

      var height = document.body.offsetHeight;

      if (value >= height / 2 && !formOpened.current) {
        formOpened.current = true;
        setForm(true);
      }

      if (imgSec1 && imgSec2 && imgSec3) {
        imgSec1.style.marginTop = "calc(-" + value / 3 + "px)";
        imgSec2.style.marginTop = "calc(-" + value / 10 + "px)";
        imgSec3.style.marginTop = "calc(-" + value / 50 + "px)";
        // imgSecLand.style.marginTop = 'calc(-' + value/20 + 'px)'
        // document.getElementById('finalImgCont').style.marginBottom = 'calc(' + imgSecLand.offsetHeight + 'px - 20vw - 150px)'
      }

      const observer = new IntersectionObserver(
        ([{ isIntersecting }]) => {
          if (isIntersecting) {
            document.querySelector(".top-toast").classList.remove("full-color");
          } else {
            document.querySelector(".top-toast").classList.add("full-color");
          }
        },
        { rootMargin: "100% 0% 0% 0%", threshold: 1.0 }
      );

      observer.observe(document.querySelector(".aecs-head"));
      const videoEl = document.querySelector(".aecs-head--video");
      videoEl.defaultMuted = true;
      videoEl.muted = true;
    });
  }, []);

  return (
    <div className="aecs">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{meta.title}</title>
        <meta name="title" content={meta.title} />
        <meta name="description" content={meta.desc} />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://www.dwellingo.in/${meta.url}`}
        />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.desc} />
        <meta
          property="og:image"
          content="https://www.dwellingo.in/logo512.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content={`https://www.dwellingo.in/${meta.url}`}
        />
        <meta property="twitter:title" content={meta.title} />
        <meta property="twitter:description" content={meta.desc} />
        <meta
          property="twitter:image"
          content="https://www.dwellingo.in/logo512.png"
        />
      </Helmet>

      <Call bgc={meta.primaryColor} />

      <div className="top-toast">
        Stays starting from 3 months, double occupancy from INR 13,000 onwards.
        <span className="topc2a" onClick={() => setForm(true)}>
          {" "}
          Book Now <ChevronHead className="headChevron" />{" "}
        </span>
      </div>
      <div className="aecs-head">
        <video
          className="aecs-head--video"
          autoPlay
          playsInline
          loop
          src="https://dwellingo-in.mo.cloudinary.net/lush-video.mov"
        />
        <div className="aecs-head--video--overlay"></div>
        <div className="property-page-top-head">
          World class co-living, co-working space right in the heart of
          Whitefield.
        </div>
        <div className="property-page-top-sub-head">Powered by Dwellingo</div>
        <div className="aecs-head--text">
          <div className="aecs-head--text--title">{meta.property}</div>
          <div className="aecs-head--text--subtitle">
            Experience fully furnished bespoke Studios at Whitefield. Combining
            comfort and luxury, Lush brings the feeling of a tropical vacation
            back home. This warm and inviting modern style offers the perfect
            blend of fun and structure.
          </div>
        </div>
      </div>

      <img
        src="https://dwellingo-in.mo.cloudinary.net/lush-main.jpg"
        loading="lazy"
        alt="Lush Images"
        className="propImgsMain"
      />
      <div className="secImgsCont">
        <img
          src="https://dwellingo-in.mo.cloudinary.net/lush-tall-1.jpg"
          loading="lazy"
          alt="Lush Images"
          className="propImgsSec"
          id="propImgsSec1"
        />
        <img
          src="https://dwellingo-in.mo.cloudinary.net/lush-tall-2.jpg"
          loading="lazy"
          alt="Lush Images"
          className="propImgsSec propImgsSec2Omb"
          id="propImgsSec2"
        />
        <img
          src="https://dwellingo-in.mo.cloudinary.net/lush-tall-3.jpg"
          loading="lazy"
          alt="Lush Images"
          className="propImgsSec propImgsSec3Omb"
          id="propImgsSec3"
        />
      </div>

      <div className="propertyInfoPara1Der">Fully furnished bespoke rooms</div>
      <div className="propertyInfoDesc1Der">
        We believe that diversity makes for a rich tapestry and all the threads
        of this stunning {meta.property} tapestry are equal in value no matter
        the colour, shape or size.
      </div>

      <div className="finalImgCont" id="finalImgCont">
        <img
          src="https://dwellingo-in.mo.cloudinary.net/lush-blob-main.jpg"
          loading="lazy"
          alt="Lush Images"
          className="last2Img1"
        />
        <img
          src="https://dwellingo-in.mo.cloudinary.net/lush-blob-sec.jpg"
          loading="lazy"
          alt="Lush Images"
          className="last2Img2"
          id="last2Img2"
        />
      </div>

      <div className="propMainTitleSizeDer work">Work</div>
      <div className="propMainTitleSizeDer liveAndLove">Live & Love</div>
      <div className="propMainTitleSizeDer underThe">while losing</div>
      <div className="propMainTitleSizeDer yourselfAt">yourself at</div>
      <div className="OmbTitleSmall">{meta.property}</div>
      <div className="getInTouchCont" onClick={() => setForm(true)}>
        GET IN TOUCH
        <img src={Chevron} alt="chevron" style={{ marginTop: "3px" }} />
      </div>
      <div style={{ width: "fit-content", margin: "auto" }}>
        <div className="iconsCont">
          {iconsArray.map((e, index) => {
            return (
              <div key={index} className="iconBoxOmb">
                <e.img className="iconsSVGOmb" />
                {e.alt}
              </div>
            );
          })}
        </div>
      </div>
      <div className="POIDer">Places of interest around you</div>
      <iframe
        className="POInearImg"
        loading="lazy"
        allowfullscreen=""
        referrerpolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62203.34557246564!2d77.7307906!3d12.9904487!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11e34ffe7d1d%3A0x4ba24b4b6ac41c1e!2sBRIGADE%20TECH%20PARK%2C%20Pattandur%20Agrahara%2C%20Whitefield%2C%20Bengaluru%2C%20Karnataka%20560066!5e0!3m2!1sen!2sin!4v1651824850486!5m2!1sen!2sin"
      ></iframe>
      <div className="getInTouchCont" onClick={() => setForm(true)}>
        GET IN TOUCH
        <img src={Chevron} alt="chevron" style={{ marginTop: "3px" }} />
      </div>
      <div className="main-carousel-cont propCarousel">
        <div className="mainCarousel propMainCarousel">
          {NearData.map((e, index) => {
            return (
              <div key={index}>
                <img
                  src={e.img}
                  alt="img"
                  className="nearPropImgs"
                  loading="lazy"
                />
                <div className="propCarouselTitle">{e.title}</div>
                <ul className="propCarouUL">
                  {e.data.map((e, index) => (
                    <li key={index}>
                      <b>• {e.location}</b> {e.distance}
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
      <Helmet
        script={[
          {
            type: "text/javascript",
            innerHTML: `
             var _ua = ( function ( u ) {
      return {
        Tablet: ( u.indexOf( "windows" ) != -1 && u.indexOf( "touch" ) != -1 && u.indexOf( "tablet pc" ) == -1 )
          || u.indexOf( "ipad" ) != -1
          || ( u.indexOf( "android" ) != -1 && u.indexOf( "mobile" ) == -1 )
          || ( u.indexOf( "firefox" ) != -1 && u.indexOf( "tablet" ) != -1 )
          || u.indexOf( "kindle" ) != -1
          || u.indexOf( "silk" ) != -1
          || u.indexOf( "playbook" ) != -1,
        Mobile: ( u.indexOf( "windows" ) != -1 && u.indexOf( "phone" ) != -1 )
          || u.indexOf( "iphone" ) != -1
          || u.indexOf( "ipod" ) != -1
          || ( u.indexOf( "android" ) != -1 && u.indexOf( "mobile" ) != -1 )
          || ( u.indexOf( "firefox" ) != -1 && u.indexOf( "mobile" ) != -1 )
          || u.indexOf( "blackberry" ) != -1
      }
    } )( window.navigator.userAgent.toLowerCase() );

    var luxyEl = document.getElementById( 'root' );
    if ( luxyEl ) {
      if ( !_ua.Mobile && !_ua.Tablet ) {
        luxy.init( {
          wrapper: '#root',
          wrapperSpeed: 0.065,

        } );
        luxy.cancel()
      } else {
        luxyEl.style.position = 'unset'
      }
    }
          `,
          },
        ]}
      />
    </div>
  );
};

export default Lush;
