import React, { useEffect, useState } from "react";

import "./styles.css";

const ContactForm = (props) => {
  return (
    <div>
      {props.open ? (
        <div className="modal-wrapper">
          <div
            onClick={() => {
              props.setOpen(false);
            }}
            className="modal-backdrop"
          />
          <div className="modal-box">
            <MainForm open={props.setOpen} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ContactForm;

const MainForm = (props) => {
  const [location, setLocation] = useState();

  useEffect(() => {
    setLocation(window.location.href.split("/")[3]);
    const scriptURL =
      "https://script.google.com/macros/s/AKfycbwtFbZExhGGC2COx5caFQVU6odW8uoOCOyWYqi_oyBbT7E_LsWoTz7JmCubFcwWcSXq4g/exec";
    const form = document.forms["google-sheet"];

    form.addEventListener("submit", (e) => {
      e.preventDefault();
      var formData = new FormData(form);
      // console.log( new FormData( form ))
      formData.append("URL", window.location.href);
      fetch(scriptURL, { method: "POST", body: formData })
        .then((response) => {
          props.open(false);
          window.location.href = "/thank-you";
          form.removeEventListener("submit", e);
        })
        .catch((error) => console.error("Error!", error.message));
    });
  }, []);

  return (
    <div className="formContainer">
      <div className="FormHeading">Help us find you the right fit!</div>
      <form method="post" name="google-sheet">
        <input
          type="text"
          name="FirstName"
          id="FirstName"
          className="FirstName"
          required
          placeholder="First Name *"
        />
        <br />
        <input
          type="text"
          name="LastName"
          id="LastName"
          className="LastName"
          required
          placeholder="Last Name *"
        />
        <br />
        <select name="location" id="location" required>
          <option
            value=""
            className="firstOption"
            disabled
            selected={!location}
          >
            Location *
          </option>
          <option
            value="Indiranagar, 100 feet road, Bangalore"
            selected={location === "derive"}
          >
            Indiranagar, 100 feet road, Bangalore
          </option>
          <option value="Koramangala, Bangalore">Koramangala, Bangalore</option>
          <option value="Whitefield, Bangalore" selected={location === "lush"}>
            Whitefield, Bangalore
          </option>
          <option
            value="H.S.R. Layout, Bangalore"
            selected={location === "sycamore" || location === "ombre"}
          >
            H.S.R. Layout, Bangalore
          </option>
        </select>
        <br />
        <select name="moveInDate" id="moveInDate" required>
          <option value="" className="firstOption" disabled selected>
            Move In Date *
          </option>
          <option value="As Soon As Possible">As Soon As Possible</option>
          <option value="2-3 Weeks From Today">2-3 Weeks From Today</option>
          <option value="A Month From Today">A Month From Today</option>
        </select>
        <br />
        <select name="budget" id="budget" required>
          <option value="" className="firstOption" disabled selected>
            Budget *
          </option>
          {[
            "INR 12,000-20,000",
            "INR 20,000-25,000",
            "INR 25,000-30,000",
            "INR 30,000-42,000",
          ].map((el) => (
            <option value={el}>{el}</option>
          ))}
        </select>

        <br />
        <input
          type="email"
          name="EmailId"
          id="EmailId"
          className="EmailId"
          required
          placeholder="Email Id *"
        />
        <br />
        <input
          type="number"
          name="phNumber"
          id="phNumber"
          className="phNumber"
          required
          placeholder="Phone Number *"
        />
        <br />
        <input
          type="submit"
          name="submit"
          className="btnSubmit"
          value="Dwellingo"
        />
      </form>
      <div className="closeModal" onClick={() => props.open(false)}>
        no thanks
      </div>
    </div>
  );
};
