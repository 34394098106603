import React, { useState } from 'react'
import { render } from 'react-dom'
import CallIcon from '../../imgs/fluent_call-connecting-20-filled.png';

import ContactForm from '../contact-form/contact-form'

const Call = (props) => {
  const [form, setForm] = useState(false)

	render(
		<ContactForm open={form} setOpen={setForm} />,
		document.getElementById('formModal')
	)

  return (
    <div className='floatingActionBtn'>
      <a href="tel:+918045687977" className='callIconFloatingBtn' style={{background: props.bgc || '#2A7560'}}>
        <img src={ CallIcon } alt="" /> Reach out
      </a>
      <div className='callIconFloatingBtn2' onClick={() => setForm(true)} style={{background: props.bgc || '#2A7560'}}>
        Request a call back
      </div>
    </div>
  );
};

export default Call
