import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import ContactForm from "../contact-form/contact-form";

import LogoBig from "../../imgs/footerLogo.png";
import Insta from "../../imgs/insta.svg";

import "./styles.css";

const Footer = (props) => {
  const [Footer, setFooter] = useState(!props.pathIndex);
  const [formStatus, setForm] = useState(false);
  var year = new Date().getFullYear();
  useEffect(() => {
    document.getElementById("div1").style.minWidth =
      document.getElementById("div2").offsetWidth + "px";
    var _ua = (function (u) {
      return {
        Tablet:
          (u.indexOf("windows") !== -1 &&
            u.indexOf("touch") !== -1 &&
            u.indexOf("tablet pc") === -1) ||
          u.indexOf("ipad") !== -1 ||
          (u.indexOf("android") !== -1 && u.indexOf("mobile") === -1) ||
          (u.indexOf("firefox") !== -1 && u.indexOf("tablet") !== -1) ||
          u.indexOf("kindle") !== -1 ||
          u.indexOf("silk") !== -1 ||
          u.indexOf("playbook") !== -1,
        Mobile:
          (u.indexOf("windows") !== -1 && u.indexOf("phone") !== -1) ||
          u.indexOf("iphone") !== -1 ||
          u.indexOf("ipod") !== -1 ||
          (u.indexOf("android") !== -1 && u.indexOf("mobile") !== -1) ||
          (u.indexOf("firefox") !== -1 && u.indexOf("mobile") !== -1) ||
          u.indexOf("blackberry") !== -1,
      };
    })(window.navigator.userAgent.toLowerCase());
    if (_ua.Mobile || _ua.Tablet) {
      setFooter(true);
    }
  }, []);
  document.addEventListener("scroll", function () {
    if (
      window.innerHeight + window.scrollY + 200 >=
      document.body.offsetHeight
    ) {
      setFooter(true);
    }
  });

  render(
    <ContactForm open={formStatus} setOpen={setForm} />,
    document.getElementById("formModal")
  );

  return (
    <div className={`footerContainer ${Footer ? "animated" : null}`}>
      <div className="footerContent">
        <div id="div1">
          <div className="footerHeading">
            think. <br /> live. <br /> thrive.
          </div>
          <a
            href="https://www.instagram.com/dwellingo_in/"
            target="_"
            className="footerC2a instaFot"
          >
            <img src={Insta} alt="" /> Instagram
          </a>
          <div className="footerSubheading">© Copyright {year}</div>
        </div>
        <a href="/" style={{ display: "block", margin: "auto" }}>
          <img src={LogoBig} alt="" className="footerImg" />
        </a>
        <div id="div2">
          <a href="/privacy-policy" className="footerC2a">
            Privacy Policy
          </a>
          <div
            className="footerC2a"
            id="footerContactUs"
            onClick={() => setForm(true)}
          >
            Contact Us
          </div>
          <a href="https://medium.com/@dwellingo" className="footerC2a">
            Medium
          </a>
          <div className="footerC2a">About Us</div>
          <a href="#" className="footerC2a">
            Terms & Conditions
          </a>
          <a href="/careers" className="footerC2a">Work at Dwellingo</a>
          <div className="footerC2a"></div>
          <a href="/thank-you" className="thankyouTemp">
            .
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
