import React from "react";
import { Helmet } from "react-helmet";
import Call from "../../components/call/Call";

import "./privacy-policy.scss";

const PricavyPolicy = () => {
  document.getElementById("loadingAnimation").style.display = "none";
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy | Dwellingo</title>
      </Helmet>
      <Call />
      <div className="privacy-policy-container">
        <h1>Privacy Policy</h1>

        <h2>Statement of Privacy Practice and Policy</h2>
        <p>
          This privacy statement informs you of our practises as well as your
          options for how we collect and utilise the information you provide
          online. Please read this privacy statement carefully and return to
          this page from time to time to check for updates. Dwellingo reserves
          the right to make changes to the policy at any time, with the new
          policy taking effect on the date specified in the privacy policy.
        </p>

        <h2>Site Covered by this Privacy Policy</h2>
        <p>
          The Dwellingo website,{" "}
          <a href="https://www.dwellingo.in/">https://www.dwellingo.in/</a>, is
          covered by this privacy statement. For your convenience, this website
          may include links to third-party websites. However, clicking on those
          links will take you away from the Dwellingo website.
        </p>
        <p>
          Any personal information you choose to share with unrelated third
          parties is not covered by this Privacy Policy. We do not monitor or
          control the information collected by such sites, or any third-party
          privacy practices, and we are not responsible for their practices or
          the content of their sites.
        </p>

        <h2>What Type Of Information Does Dwellingo.in Collect From You?</h2>
        <ul>
          <li>
            Dwellingo may collect Personal Information from you when you
            register on Dwellingo in order to provide you with the material
            and/or services you've requested. "Personal information" or
            "personal data," as used in this policy, refers to information about
            an identifiable individual that is protected by law in the country
            in which you reside. In certain jurisdictions, business contact
            information is not considered "personal information" or "personal
            data." Dwellingo collects data in a number of methods and for a
            variety of reasons.
          </li>
          <li>
            You will be asked to submit contact information if you wish to
            register with Dwellingo (name, address, telephone number, email
            address). We'll use your information to contact you about the
            services on our site that you've indicated interest in.
          </li>
          <li>
            Your contact information may also be used to give you information
            about additional Dwellingo products and services, as well as
            promotional material from some of our marketing partners that may be
            of interest to you. If you do not wish to receive these promotional
            communications, you may opt out when you supply us with your contact
            information or afterwards by amending your choices (see the Opt-Out
            section below).
          </li>
          <li>
            You may reserve co-living or co-working services, request
            information, and subscribe to marketing or support materials through
            Dwellingo website. We will need specific information from you in
            order to acquire a product or service from us. You must supply
            contact information (such as your name, email address, phone number,
            and an ID proof) as well as financial information (such as credit
            card number, billing address, and expiration date). The financial
            information you provide will only be used to bill you for the items
            and services you have purchased.
          </li>
          <li>
            You have the option of providing demographic data as well (such as
            type of business, size of business, locations, etc.). This
            demographic information is used by Dwellingo to better understand
            your requirements and interests and to offer you with a more
            personalised experience on our website. This information is used to
            process orders, allow you to participate in promotions, and make
            your relationship with us easier.
          </li>
          <li>
            Please be aware that your email address is not shared with our
            commercial partners. However, on behalf of our commercial partners,
            we may send you offers. You can choose not to receive email offers
            from Dwellingo by following the steps outlined below.
          </li>
          <li>
            If you no longer desire to receive our promotional/commercial
            mailings, please email us at help@dwellingto let us know. In the
            subject line, put "Opt-Out."
          </li>
          <li>
            <h3>Cookies and other Digital Markers</h3>
            <p>
              Cookies and other digital identifiers are used by Dwellingo to
              collect information automatically. Cookies and digital markers are
              little computer files that enable us to identify you and monitor
              your visit to our website. They may be saved on your computer's
              hard disc or integrated in our website pages. You may disable
              cookies on your computer by adjusting the settings in your
              browser's preferences or options menu. You may not be able to
              access certain portions of the website if you disable cookies.
            </p>
          </li>
          <li>
            <h3>Session Data</h3>
            <p>
              Dwellingo gathers technical information about your visit
              automatically (such as your browser type, Internet service
              provider, platform type, IP addresses, referring/exit pages,
              operating system, and date/time stamp). This information is not
              linked to any other information we have about you. We use this
              data to compile reports, evaluate trends, identify server
              problems, administer the website, track user movement and usage,
              and compile broad demographic data.
            </p>
          </li>
        </ul>

        <h2>Does Dwellingo Protect My Personal Information?</h2>
        <p>
          We secure personal data provided to us by following generally
          acknowledged industry technological standards, both during
          transmission and once we receive it. We use secure socket layer
          technology to encrypt sensitive information (such as credit card
          information) that you input on our registration or order forms.
          Dwellingo cannot ensure the security of our servers, nor can we
          guarantee that information you provide through the Dwellingo website
          will not be intercepted while being transported over the Internet.
        </p>

        <h2>How Does Dwellingo Use The Information That It Collects?</h2>
        <p>
          We and/or approved third parties/service partners use your Personal
          Information and User Information to support your engagement with us,
          to give you the best discounts and pricing on products and services,
          and to contact you again about additional services and products that
          we provide. By providing Dwellingo with your Personal Information and
          User Information, you fully accept and consent to Dwellingo's use and
          processing of such information in any manner Dwellingo deems
          appropriate. This may include data analysis, deal research based on
          evaluations, and the transmission of your Personal Information to
          third-party specialists for the purpose of Dwellingo's outsourced
          services, among other things.
        </p>

        <h2>Contacting Us</h2>
        <p>
          Your feedback is important to us. If you have any questions or
          complaints about our Privacy Policy, please send us an email or
          contact us at <a href="tel:+919246635454">+91 92466 35454</a> , and we
          will do our best to respond within a fair timescale. Please include
          "Privacy" in the subject line of your e-mail if your issue is about
          privacy.{" "}
        </p>
      </div>
    </div>
  );
};

export default PricavyPolicy;
